:root {
	--color-primary: #0680BF;
	--color-primary-dark: #2e5285;
	--color-primary-light: #00AEEF;
	--color-green: #4caf50;
	--color-red: #f44336;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #cccccc;
	--color-grey-dark-1: #434545;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0;
	padding: 0;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Open Sans', sans-serif;
}

/* APP */
.app {
	min-height: 100vh;
}
header {
	background-color: var(--color-grey-dark-1);
	display: flex;
	flex-direction: column;
	width: 100%;
	position: fixed;
	z-index: 9;
	box-shadow: var(--shadow-dark);
}
.header__pages {
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.header__pages-container {
	display: flex;
	align-items: center;
}
.header__pages-container a {
	padding: 1rem 2rem;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	filter: grayscale(1);
}
.header__pages-container a:hover {
	filter: grayscale(0);
}
.header__container {
	width: 100%;
	max-width: 120rem;
	display: flex;
	margin: 0 auto;
}
.header__container>a {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 4.5rem;
}
.header__container a img {
	height: 4rem;
}
.header__container ul {
	flex: 1;
	display: flex;
	list-style: none;
}
.header__container ul a {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	font-size: 1.4rem;
	text-decoration: none;
	color: #fff;
	text-transform: uppercase;
	position: relative;
}
.header__container ul a span {
	position: relative;
	text-align: center;
}
.header__container ul a::before {
	content: "";
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 0;
	background-color: var(--color-primary-light);
	transition: all .5s;
}
.header__container ul a:hover::before {
	height: 100%;
}
.footer {
	position: relative;
	z-index: 3;
    min-height: 30vh;
    background-color: var(--color-grey-dark-1);
}
.footer > .footer__container {
    flex: 1;
}
.footer__container {
    width: 120rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.footer__container ul {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 2rem 2rem;
    font-size: 1.4rem;
    list-style: none;
    flex: 1;
    text-transform: uppercase;
}
.footer__container ul.fl-2 {
    flex: 2 !important;
}
.footer__container ul a {
    font-size: 1.4rem;
    color: var(--color-grey-dark-3);
    text-decoration: none;
    text-transform: uppercase;
}
.footer__container ul a:hover {
    color: #fff;
}
.footer__container ul li {
    display: flex;
    flex-direction: column;
}
.footer__container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
}
.footer__container form span {
    font-size: 2rem;
    text-align: center;
    padding: 0 0 2rem;
    color: #fff;
}
.footer__input-container {
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 5rem;
    display: flex;
    width: 100%;
}
.footer__input-container input {
    flex: 2;
    border: none;
    background-color: transparent;
    outline: none;
    color: #fff;
    padding: .5rem .5rem;
}
.footer__input-container button {
    flex: 1;
    border: 1px solid #fff;
    padding: .5rem 2rem;
    border-radius: 5rem;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
}
.footer__input-container button:hover {
    background-color: #fff;
    color: #333;
}
.footer__divider {
    height: 5rem;
    background-color: #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
ul.footer__container {
    list-style: none;
}
ul.footer__container li {
    font-size: 1.4rem;
    color: #fff;
}
ul.footer__container li a {
    font-size: 1.4rem;
    color: var(--color-grey-dark-3);
    text-decoration: none;
    text-transform: uppercase;
}
ul.footer__container li a:hover {
    color: #fff;
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	color: var(--color-primary-dark);
	text-transform: uppercase;
	font-size: 1.2rem;
}
.input-container input,
.input-container textarea {
	padding: 1rem;
	outline: none;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid var(--color-primary-dark);
	color: var(--color-primary-dark);
}
.input-container button {
	padding: 1rem;
	border: 1px solid var(--color-primary-dark);
	color: var(--color-primary-dark);
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
}
.input-container button:hover {
	background-color: var(--color-primary-dark);
	color: #fff;
}

/* HOME */
.home {
	padding-top: 6rem;
}
.home__cover {
	height: 80vh;
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100%;
	top: 6rem;
	z-index: 1;
}
.home__cover ul {
	flex: 0 0 50%;
	display: flex;
	list-style: none;
}
.home__cover ul a {
	flex: 1;
	filter: grayscale(100%);
	cursor: pointer;
	transition: all .5s;
	padding: 2.5rem;
	background-size: cover;
	background-position: center;
}
.home__cover ul a:hover {
	filter: grayscale(0);
}
.home__cover ul a h3 {
	position: absolute;
	bottom: 2.5rem;
	right: 2.5rem;
	font-size: 2.5rem;
	color: #fff;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.home__cover ul:first-of-type a:first-of-type {
	flex: 0 0 50%;
	background-image: url('/images/img1.jpg');
}
.home__cover ul:first-of-type a:nth-of-type(2) {
	background-image: url('/images/1382.jpg');
}
.home__cover ul:first-of-type a:nth-of-type(3) {
	background-image: url('/images/1047.jpg');
}
.home__cover ul:last-of-type a:first-of-type {
	background-image: url('/images/11.jpg');
}
.home__cover ul:last-of-type a:nth-of-type(2) {
	background-image: url('/images/5.jpg');
}
.home__cover ul:last-of-type a:nth-of-type(3) {
	background-image: url('/images/131.jpg');
}
.home__cover ul:last-of-type a:nth-of-type(4) {
	background-image: url('/images/img20.jpg');
}
.home__first {
	position: relative;
	margin-top: 80vh;
	min-height: 60vh;
	z-index: 3;
	background-image: url('/images/1.png');
	display: flex;
	background-size: cover;
	background-position: center;
    box-shadow: 2px -3px 10px rgba(0,0,0,.5);
}
.home__first-container {
	width: 100%;
	max-width: 120rem;
	margin: auto;
	padding: 2.5rem;
}
.home__first-container h1 {
	text-align: center;
	font-size: 4.5rem;
	color: #fff;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	text-shadow: 1px 1px 1px var(--color-grey-dark-1);
	letter-spacing: 3px;
}
.home__first-container h1 small {
	font-size: 2.5rem;
	color: #fff;
}
.home__first-container p {
	text-align: center;
	margin-top: 1rem;
	font-size: 1.6rem;
	color: #fff;
	text-transform: uppercase;
	font-weight: 800;
	text-shadow: 1px 1px 1px var(--color-grey-dark-1);
}
.home__second {
	padding: 12rem 0;
	background-color: #fff;
	display: flex;
	z-index: 3;
	position: relative;
}
.home__second-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.home__second-container h2 {
	font-size: 2.5rem;
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary);
	color: var(--color-primary);
	text-transform: uppercase;
	letter-spacing: 3px;
}
.home__second-container p {
	padding: 2.5rem;
	font-size: 1.4rem;
	letter-spacing: 1px;
	text-align: justify;
}
.home__second-container p a {
	color: var(--color-primary);
}
.home__three {
	position: relative;
	z-index: 3;
	background-image: url('/images/2.png');
	background-size: cover;
	background-position: center;
	padding: 9rem 0;
}
.home__three-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.home__three-container h2 {
	font-size: 2.5rem;
	color: #fff;
	padding: 1rem;
	border-bottom: 1px solid #fff;
	letter-spacing: 3px;
	text-transform: uppercase;
	text-shadow: 1px 1px 2px var(--color-grey-dark-1);
}
.home__three-container ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 2.5rem;
}
.home__three-container ul li {
	flex: 0 0 15%;
	padding: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.home__three-container ul li img {
	width: 100%;
}
.home__contact {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
	background-color: #fff;
	position: relative;
	z-index: 3;
}
.home__contact-container {
    width: 120rem;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
}
.home__contact-container h3 {
    flex: 0 0 100%;
    text-align: center;
    padding: 0 0 4.5rem;
    font-size: 2.5rem;
    color: var(--color-primary-dark);
}
.home__contact-middle-container {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
}
.home__contact-middle-container form {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 2rem;
    justify-content: space-between;
}
.home__contact-middle-container form button {
    align-self: flex-start;
    padding: 1rem 4rem;
    border: 3px solid var(--color-grey-dark-2);
    color: var(--color-grey-dark-2);
    cursor: pointer;
    background-color: transparent;
    margin-left: 2rem;
    margin-top: 2rem;
    text-transform: uppercase;
}
.home__contact-middle-container form button:hover {
    background-color: var(--color-grey-dark-2);
    color: #fff;
}
.home__input-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}
.home__input-container label {
    text-transform: uppercase;
}
.home__input-container input {
    padding: 1rem;
    color: var(--color-grey-dark-1);
    outline: none;
    background-color: #fff;
    border: 1px solid var(--color-grey-dark-3);
    margin-top: 1rem;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home__input-container textarea {
    padding: 1rem;
    color: var(--color-grey-dark-1);
    outline: none;
    background-color: #fff;
    border: 1px solid var(--color-grey-dark-3);
    margin-top: 1rem;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home__contact-middle-container h4 {
    font-size: 4.5rem;
    padding: 0 0 1rem;
    color: var(--color-grey-dark-1);
}
.home__contact-phone {
    display: flex;
    font-size: 3.5rem;
    align-items: center;
    font-weight: 600;
    margin-top: 2.5rem;
    color: var(--color-grey-dark-1);
}
.home__contact-phone i {
    margin-right: 1rem;
    color: var(--color-grey-light-1);
    background-color: var(--color-grey-dark-1);
    padding: 1rem;
    border-radius: 50%;
    font-size: 3rem;
}
.home__contact-middle-container > ul {
    list-style: none;
}
.home__contact-middle-container > ul > li {
    display: flex;
    flex-direction: column;
}
.home__contact-middle-container > ul > li > span {
    padding: 1rem 2rem;
}
.home__contact-middle-container > ul > li > ul {
    padding: 1rem 4rem;
}
.home__customer-logos {
    width: 120rem;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
}

/* ABOUT */
.about {
	min-height: 100vh;
	padding-top: 6rem;
}
.about__cover {
	min-height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('/images/3.png');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}
.about__cover h1 {
	font-size: 4.5rem;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	text-shadow: 1px 1px 2px var(--color-grey-dark-1);
}
.about__content {
	padding: 2.5rem 2.5rem 4.5rem;
}
.about__content-container {
	width: 100%;
	max-width: 80rem;
	margin: 0 auto;
}
.about__content-container h3 {
	font-size: 2.5rem;
	text-transform: uppercase;
	color: var(--color-primary-dark);
	letter-spacing: 3px;
	border-bottom: 1px solid var(--color-primary-dark);
	margin-top: 4.5rem;
}
.about__content-container p {
	margin-top: 2rem;
	font-size: 1.4rem;
	text-align: justify;
}

/* PRODUCTS */
.products {
	min-height: 100vh;
	padding-top: 6rem;
}
.products__container {
	margin: 0 auto;
	width: 100%;
	max-width: 120rem;
	display: flex;
}
.products__categories {
	flex: 0 0 20%;
	list-style: none;
	padding: 4.5rem 0 ;
}
.products__categories li {
	padding: 2rem;
	text-transform: uppercase;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 1.2rem;
	color: var(--color-primary);
}
.products__categories li:hover,
.products__categories li.active {
	background-color: var(--color-primary);
	color: #fff;
}
.products__content {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	padding: 2.5rem;
	justify-content: space-between;
	align-items: flex-start;
}
.products__item {
	flex: 0 0 31%;
	text-decoration: none;
}
.products__item picture {
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	height: 25rem;
}
.products__item picture img {
	width: 100%;
}
.products__item h3 {
	font-size: 1.4rem;
	text-transform: uppercase;
	color: var(--color-primary);
}
.products__item p {
	color: var(--color-grey-dark-3);
	font-size: 1.2rem;
	text-align: justify;
	margin-top: 2rem;
}

/* CONTACT */
.contact {
	min-height: 100vh;
	padding-top: 6rem;
	display: flex;
}
.contact__bg {
	flex: 1;
	background-image: url('/images/4.png');
	background-size: cover;
	background-position: left;
}
.contact__form {
	flex: 1;
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 2.5rem;
}
.contact__form form {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 40rem;
}

/* SINGLE-PRODUCT */
.single-product {
	min-height: 100vh;
	padding-top: 6rem;
}
.single-product__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
}
.single-product__carousel {
	flex: 0 0 50%;
	width: 20rem;
	padding: 2.5rem;
}
.single-product__image {
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
}
.single-product__image img {
	width: 100%;
}
.single-product__content {
	padding: 2.5rem;
	flex: 1;
	display: flex;
	flex-direction: column;
}
.single-product__content h1 {
	font-size: 2.5rem;
	text-transform: uppercase;
	color: var(--color-primary);
}
.single-product__content button {
	padding: 1rem;
	background-color: var(--color-primary);
	color: #fff;
	cursor: pointer;
	border: none;
	text-transform: uppercase;
	margin-top: 1rem;
	outline: none;
}
.single-product__content h3 {
	font-size: 1.8rem;
	text-transform: uppercase;
	color: var(--color-primary-light);
	margin-top: 2rem;
	font-weight: 600;
}
.single-product__content p {
	font-size: 1.4rem;
	padding: 1rem;
	text-align: justify;
}

/* RESPONSIVE */
@media only screen and (max-width: 48em) {
	.home__contact-container {
		flex-direction: column-reverse;
		width: 100%;
	}
	.home__contact-middle-container {
		padding: 2.5rem;
	}
	.home__contact-middle-container form {
		padding: 0;
	}
	.footer__container {
		flex-wrap: unset;
		flex-direction: column;
	}
	.home__cover ul:first-of-type a:first-of-type {
		display: none;
	}
	.home__cover ul:last-of-type a:first-of-type {
		display: none;
	}
	.home__cover ul:last-of-type a:nth-child(2) {
		display: none;
	}
	.footer__container {
		width: 100%;
		padding: 2rem;
	}
	.footer__divider {
		height: unset;
	}
}
@media only screen and (max-width: 48rem) {
	.contact__form {
		flex: 2;
	}
	.contact__bg {
		display: none;
	}
	.header__container>a {
		display: none;
	}
	.products__content {
		flex-wrap: unset;
		flex-direction: column;
	}
	.products__item {
		flex: unset;
		margin-top: 2.5rem;
	}
	.single-product__container {
		flex-direction: column;
		align-items: unset;
	}
	.single-product__carousel {
		flex: unset;
		width: 100%;
		padding: 4.5rem;
	}
}